<template>
    <b-container>
        <div>
            <b-spinner class="mt-5 mb-2 mb-md-4" large label="Large Spinner"></b-spinner>
        </div>
    </b-container>
</template>

<script>
import ApiService from '../../apiService.js'
import config from '../../config'
import { mapActions } from 'vuex'

export default {
  name: 'ConfirmEmail',
  mounted () {
    this.confirmEmail()
  },
  methods: {
    ...mapActions([
      'processJwt'
    ]),
    confirmEmail () {
      let token = this.$route.params.token
      ApiService.confirmToken(token).then((response) => {
        if (config.google_tag_manager !== '' && config.tracking_enabled === 'true') {
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({
            'event': 'accountValidation'
          })
        }
        localStorage.setItem('accessToken', response.data.data.token)
        this.processJwt().then(() => {
          this.$store.state.emailConfirmTokenSuccessful = true
          this.$router.push('/')
        })
      }).catch(() => {
        this.$store.state.emailConfirmTokenSuccessful = false
        this.$router.push('/')
      })
    }
  }
}
</script>

<style scoped>

</style>
